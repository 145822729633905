import axios from "axios";
import { setUpMarkerLayout } from "./set-up-marker-layout";

export function setUpDraggableLabel(options) {
  let mouseStartX = 0;
  let mouseStartY = 0;
  let markerStartX = 0;
  let markerStartY = 0;
  options.label.onmousedown = dragMouseDown;

  function dragMouseDown(event) {
    event = event || window.event;
    event.preventDefault();
    // get the mouse cursor position at startup:
    mouseStartX = event.clientX;
    mouseStartY = event.clientY;

    markerStartX = options.label.offsetLeft;
    markerStartY = options.label.offsetTop;

    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
    document.onmouseup = closeDragElement;
  }

  function elementDrag(event) {
    event = event || window.event;
    event.preventDefault();
    // calculate the new cursor position:
    const mouseDeltaX = event.clientX - mouseStartX;
    const mouseDeltaY = event.clientY - mouseStartY;

    setUpMarkerLayout({
      label: options.label,
      line: options.line,
      x: markerStartX + mouseDeltaX,
      y: markerStartY + mouseDeltaY,
      showLabelLine: options.showLabelLine
    });
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    axios
      .patch(`/api/${options.markerType}/${options.id}/`, {
        label_offset_x: parseInt(options.label.style.left),
        label_offset_y: parseInt(options.label.style.top)
      })
      .then(() => {})
      .catch(error => {
        console.log(error);
      });
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

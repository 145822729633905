export default class ButtonDropdown {
  constructor(el) {
    if (el) {
      const button = el.getElementsByClassName("js-button")[0];
      const list = el.getElementsByClassName("js-list")[0];

      // Detect 'off-click' and close dropdown if open
      document.addEventListener("click", e => {
        if (
          e.target !== button &&
          e.target !== list &&
          list.classList.contains("js-open")
        ) {
          list.classList.remove("js-open");
        }
      });

      button.addEventListener("click", () => {
        if (list.classList.contains("js-open")) {
          list.classList.remove("js-open");
        } else {
          list.classList.add("js-open");
        }
      });
    }
  }
}

import MarkerMap from "./MarkerMap";

export default class MapOverlay {
  constructor(buttonEl) {
    if (buttonEl) {
      const areaId = buttonEl.getAttribute("data-area-id");
      const areaName = buttonEl.getAttribute("data-area-name");
      this.areaHistory = [{ id: areaId, name: areaName }];
      this.handleOpenOverlay = this.handleOpenOverlay.bind(this);
      this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
      this.handleAreaClick = this.handleAreaClick.bind(this);
      this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
      buttonEl.addEventListener("click", this.handleOpenOverlay);
    }
  }

  handleOpenOverlay() {
    const currArea = this.areaHistory[this.areaHistory.length - 1];
    const html = `
      <div class="MapOverlay">
        <div class="MapOverlay-inner">
          <div class="MapOverlay-header">
            <img class="MapOverlay-headerLogo" src="${window.bridge.logo}">
            <div class="MapOverlay-headerTab">
              <h1 class="MapOverlay-headerTitle">${currArea.verbose_name}</h1>
            </div>
            <button class="MapOverlay-closeButton">
              <img src="${window.bridge.close_white}">
            </button>
          </div>
          <div class="MarkerMap" data-area-id="${currArea.id}"></div>
          <div class="MapOverlay-footer">
            <button class="MapOverlay-backButton">Back</button>
            <a class="Key" href="${window.bridge.course_definitions_page_url}" target="_blank">
              <div class="Key-title"><p>Rating key<p></div>
              <div class="Key-icons">
                <img class="Key-icon" src="${window.bridge.ratings["01-5b"]}" alt="${window.bridge.rating_defs["01-5b"]}" title="${window.bridge.rating_defs["01-5b"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["02-5a"]}" alt="${window.bridge.rating_defs["02-5a"]}" title="${window.bridge.rating_defs["02-5a"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["03-4b"]}" alt="${window.bridge.rating_defs["03-4b"]}" title="${window.bridge.rating_defs["03-4b"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["04-4a"]}" alt="${window.bridge.rating_defs["04-4a"]}" title="${window.bridge.rating_defs["04-4a"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["05-3b"]}" alt="${window.bridge.rating_defs["05-3b"]}" title="${window.bridge.rating_defs["05-3b"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["06-3a"]}" alt="${window.bridge.rating_defs["06-3a"]}" title="${window.bridge.rating_defs["06-3a"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["07-2a"]}" alt="${window.bridge.rating_defs["07-2a"]}" title="${window.bridge.rating_defs["07-2a"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["09-u"]}" alt="${window.bridge.rating_defs["09-u"]}" title="${window.bridge.rating_defs["09-u"]}" >
                <img class="Key-icon" src="${window.bridge.ratings["08-p"]}" alt="${window.bridge.rating_defs["08-p"]}" title="${window.bridge.rating_defs["08-p"]}" >
                <img class="Key-icon Key-stayPlay" src="${window.bridge.stay_and_play}" alt="${window.bridge.stay_and_play_def}" title="${window.bridge.stay_and_play_def}" >
              </div>
            </a>
          </div>
        </div>
      </div>
    `;
    // Add the overlay to the DOM
    this.overlayEl = document.createElement("div");
    this.overlayEl.innerHTML = html;
    document.body.appendChild(this.overlayEl);

    // Close button
    this.closeButton = this.overlayEl.getElementsByClassName(
      "MapOverlay-closeButton"
    )[0];
    this.closeButton.addEventListener("click", this.handleCloseOverlay);

    // Close with background click
    this.overlayBackground = this.overlayEl.getElementsByClassName(
      "MapOverlay"
    )[0];
    this.overlayEl.addEventListener("click", e => {
      if (e.target === this.overlayBackground) this.handleCloseOverlay();
    });

    // Back button
    this.backButton = this.overlayEl.getElementsByClassName(
      "MapOverlay-backButton"
    )[0];
    this.backButton.addEventListener("click", this.handleBackButtonClick);

    // Marker map
    const markerMapEl = this.overlayEl.getElementsByClassName("MarkerMap")[0];
    const markerMapTitle = this.overlayEl.getElementsByClassName(
      "MapOverlay-headerTitle"
    )[0];
    this.markerMap = new MarkerMap(markerMapEl, true, markerMapTitle);
    this.markerMap.el.addEventListener("areaClick", this.handleAreaClick);
  }

  handleCloseOverlay() {
    this.closeButton.removeEventListener("click", this.handleCloseOverlay);
    document.body.removeChild(this.overlayEl);
  }

  handleAreaClick(e) {
    this.areaHistory.push(e.detail);
  }

  handleBackButtonClick() {
    if (this.areaHistory.length > 1) {
      this.areaHistory.pop();
      const currArea = this.areaHistory[this.areaHistory.length - 1];
      this.markerMap.updateAreaId(currArea.id);
    }
  }
}

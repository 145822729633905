export default class ImageGallery {
  constructor(el) {
    if (el) {
      this.imagesLarge = Array.from(el.getElementsByClassName("js-imageLarge"));
      this.imagesThumb = Array.from(el.getElementsByClassName("js-imageThumb"));
      this.currentImage = this.imagesLarge[0];
      this.currentImage.classList.add("js-active");

      this.imagesThumb.forEach((imageThumb, index) => {
        imageThumb.addEventListener("click", () => {
          if (!this.imagesLarge[index].classList.contains("js-active")) {
            this.currentImage.classList.remove("js-active");
            this.imagesLarge[index].classList.add("js-active");
            this.currentImage = this.imagesLarge[index];
          }
        });
      });
    }
  }
}

import { AREAS, COURSES, COURSE_GROUPS, GREEN } from "../core/constants";

import { setUpDraggableLabel } from "./set-up-draggable-label";
import { setUpMarkerLayout } from "./set-up-marker-layout";

const getAreaLabelInnerHTML = labelText => {
  return `<div class="marker-label-inner"><p class="marker-label-text">${labelText}</p></div>`;
};

const getCourseGroupMarkerInnerHTML = data => {
  const stayAndPlay = data.stayAndPlay
    ? `<img src="${window.bridge.stay_and_play}">`
    : "";
  const ratingIcons = data.courses
    .map(course => {
      return data.isEditMode
        ? `<div><img src="${window.bridge.ratings[course.rating]}"></div>`
        : `<div><a href=${course.link} target="_blank"><img src="${
            window.bridge.ratings[course.rating]
          }" alt="${course.searchName}" title="${
            course.searchName
          }"></a></div>`;
    })
    .join("");
  if (data.isEditMode) {
    return `<div class="marker-label-inner"><p class="marker-label-text">${stayAndPlay}${data.labelText}</p><div class="marker-ratings">${ratingIcons}</div></div>`;
  } else {
    return `<div class="marker-label-inner"><a href="${data.courses[0].link}" target="_blank"><p class="marker-label-text">${stayAndPlay}${data.labelText}</p></a><div class="marker-ratings">${ratingIcons}</div></div>`;
  }
};

const getCourseLabelInnerHTML = data => {
  const stayAndPlay = data.stayAndPlay
    ? `<img src="${window.bridge.stay_and_play}">`
    : "";
  let html;
  if (data.rating === "") {
    html = `<p class="marker-label-text">${stayAndPlay}${data.labelText} (future)</p>`;
  } else {
    html = `<p class="marker-label-text">${stayAndPlay}${
      data.labelText
    }</p><div class="marker-ratings"><img src="${
      window.bridge.ratings[data.rating]
    }" alt="${data.searchName}" title="${data.searchName}"></div>`;
  }
  if (data.isEditMode) {
    return `<div class="marker-label-inner">${html}</div>`;
  } else {
    return `<div class="marker-label-inner"><a href="${data.link}" target="_blank">${html}</a></div>`;
  }
};

export function initCustomMarker() {
  function CustomMarker(data) {
    // Initialize all properties.
    this.latlng = data.latlng;
    this.className = data.className;
    this.isEditMode = data.isEditMode;
    this.labelText = data.labelText;
    this.link = data.link;
    this.rating = data.rating;
    this.stayAndPlay = data.stayAndPlay;
    this.labelOffsetX = data.labelOffsetX;
    this.labelOffsetY = data.labelOffsetY;
    this.id = data.id;
    this.markerType = data.markerType;
    this.showLabelLine = data.showLabelLine;
    this.callback = data.callback;
    this.courses = data.courses;
    this.courseName = data.courseName;
    this.searchName = data.searchName;
    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    this.markerContainer = null;
  }

  CustomMarker.prototype = new window.google.maps.OverlayView();

  CustomMarker.prototype.onAdd = function () {
    // The containing div
    const markerContainer = document.createElement("div");
    this.markerContainer = markerContainer;
    this.markerContainer.className = this.className;
    // The label outer
    this.label = document.createElement("div");
    this.label.className = "marker-label";
    let markerInnerHTML;
    switch (this.markerType) {
      case AREAS:
        markerInnerHTML = getAreaLabelInnerHTML(this.labelText);
        break;
      case COURSE_GROUPS:
        markerInnerHTML = getCourseGroupMarkerInnerHTML({
          labelText: this.labelText,
          courses: this.courses,
          stayAndPlay: this.stayAndPlay,
          isEditMode: this.isEditMode
        });
        break;
      case COURSES:
        markerInnerHTML = getCourseLabelInnerHTML({
          labelText: this.labelText,
          link: this.link,
          rating: this.rating,
          stayAndPlay: this.stayAndPlay,
          courseName: this.courseName,
          searchName: this.searchName,
          isEditMode: this.isEditMode
        });
        break;
    }
    // The label inner, which may be a link or contain an image
    this.label.innerHTML = markerInnerHTML;
    // The line SVG
    const lineSVG = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    lineSVG.setAttribute("class", "marker-line-svg");
    // The arrow
    const arrowDefs = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "defs"
    );
    const arrowMarker = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "marker"
    );
    arrowMarker.setAttribute("id", "arrow");
    arrowMarker.setAttribute("viewBox", "0 0 10 10");
    arrowMarker.setAttribute("refX", "5");
    arrowMarker.setAttribute("refY", "5");
    arrowMarker.setAttribute("markerUnits", "strokeWidth");
    arrowMarker.setAttribute("markerWidth", "8");
    arrowMarker.setAttribute("markerHeight", "8");
    arrowMarker.setAttribute("orient", "auto-start-reverse");
    const arrowPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    arrowPath.setAttribute("d", "M 0 0 L 10 5 L 0 10 z");
    arrowPath.setAttribute("fill", "black");
    // The line
    this.line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    this.line.setAttribute("stroke-width", "2");
    this.line.setAttribute(
      "stroke",
      this.markerType === AREAS ? "black" : GREEN
    );
    // Add elements into the SVG container
    arrowMarker.append(arrowPath);
    arrowDefs.append(arrowMarker);
    lineSVG.append(arrowDefs);
    lineSVG.append(this.line);
    // The dot
    if (this.markerType === COURSES || this.markerType === COURSE_GROUPS) {
      this.dot = document.createElement("div");
      this.dot.className = "marker-dot";
      this.markerContainer.appendChild(this.dot);
    }
    // Add elements to the container
    this.markerContainer.appendChild(this.label);
    this.markerContainer.appendChild(lineSVG);
    // Make the label draggable if in edit mode
    if (this.isEditMode) {
      setUpDraggableLabel({
        label: this.label,
        id: this.id,
        markerType: this.markerType,
        line: this.line,
        showLabelLine: this.showLabelLine
      });
    }
    // Add the marker to the overlay layer
    const panes = this.getPanes();
    panes.overlayImage.appendChild(this.markerContainer);
    // Attach the click handler to the label
    if (this.callback !== undefined) {
      this.label.onclick = this.callback;
    }
    // Set the layout of the elements that make up the marker
    setUpMarkerLayout({
      label: this.label,
      line: this.line,
      x: this.labelOffsetX,
      y: this.labelOffsetY,
      showLabelLine: this.showLabelLine
    });
  };

  CustomMarker.prototype.draw = function () {
    const overlayProjection = this.getProjection();
    // The actual location of Area or Course as a point with x/y values
    const markerPoint = overlayProjection.fromLatLngToDivPixel(this.latlng);

    // Set the position of the containing div to these coordinates
    this.markerContainer.style.left = markerPoint.x + "px";
    this.markerContainer.style.top = markerPoint.y + "px";
  };

  CustomMarker.prototype.remove = function () {
    // Remove elements from the container
    this.markerContainer.parentNode.removeChild(this.markerContainer);
    this.markerContainer = null;
  };

  return CustomMarker;
}

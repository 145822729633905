export const getScript = (source, callback) => {
  var script = document.createElement("script");
  var prior = document.getElementsByTagName("script")[0];
  script.async = 1;

  script.onload = script.onreadystatechange = (_, isAbort) => {
    if (
      isAbort ||
      !script.readyState ||
      /loaded|complete/.test(script.readyState)
    ) {
      script.onload = script.onreadystatechange = null;
      script = undefined;
      if (!isAbort) if (callback) callback();
    }
  };

  script.src = source;
  prior.parentNode.insertBefore(script, prior);
};

export const getDistance = (a, b) => {
  const aSquared = Math.pow(a.x - b.x, 2);
  const bSquared = Math.pow(a.y - b.y, 2);
  const distanceSquared = aSquared + bSquared;
  const distance = Math.sqrt(distanceSquared);
  return distance;
};

import { getDistance } from "../core/utils";

export const setUpMarkerLayout = options => {
  options.label.style.left = `${options.x}px`;
  options.label.style.top = `${options.y}px`;

  const labelRect = options.label.getBoundingClientRect();
  const labelHeight = labelRect.height;
  const labelWidth = labelRect.width;

  const leftMiddle = {
    x: options.x - labelWidth / 2,
    y: options.y
  };
  const rightMiddle = {
    x: options.x + labelWidth / 2,
    y: options.y
  };
  const topMiddle = {
    x: options.x,
    y: options.y + labelHeight / 2
  };
  const bottomMiddle = {
    x: options.x,
    y: options.y - labelHeight / 2
  };

  const labelPoints = [leftMiddle, rightMiddle, topMiddle, bottomMiddle];
  const origin = { x: 0, y: 0 };
  const closestPoint = labelPoints.reduce((min, point) => {
    return getDistance(origin, point) < getDistance(origin, min) ? point : min;
  });

  // Are there any rating icons to consider?
  const markerRatings = options.label.getElementsByClassName(
    "marker-ratings"
  )[0];
  if (markerRatings !== undefined) {
    if (closestPoint === rightMiddle) {
      // If closest point is right middle, ratings should be placed left
      markerRatings.classList.add("place-left");
      markerRatings.classList.remove("place-right");
    } else {
      // Otherwise, ratings should be placed right
      markerRatings.classList.add("place-right");
      markerRatings.classList.remove("place-left");
    }
  }

  const distance = Math.sqrt(
    closestPoint.x * closestPoint.x + closestPoint.y * closestPoint.y
  );

  // For Courses, we always just draw from the label to the marker
  if (options.showLabelLine === "on") {
    options.line.setAttribute("x1", 0);
    options.line.setAttribute("y1", 0);
    options.line.setAttribute("x2", closestPoint.x);
    options.line.setAttribute("y2", closestPoint.y);
  } else {
    if (distance > 100) {
      // If the distance is greater than 100px, draw a line from the label towards the marker, capped at max 50px length
      const cappedLength = 50;
      const cappedX = (closestPoint.x / distance) * cappedLength;
      const cappedY = (closestPoint.y / distance) * cappedLength;
      options.line.setAttribute("x1", closestPoint.x - cappedX);
      options.line.setAttribute("y1", closestPoint.y - cappedY);
      options.line.setAttribute("x2", closestPoint.x);
      options.line.setAttribute("y2", closestPoint.y);
      options.line.setAttribute("marker-start", "url(#arrow)");
    } else if (distance > 50) {
      // If the distance is greater than 50px, draw a line from the label to the marker
      options.line.setAttribute("x1", 0);
      options.line.setAttribute("y1", 0);
      options.line.setAttribute("x2", closestPoint.x);
      options.line.setAttribute("y2", closestPoint.y);
      options.line.setAttribute("marker-start", "");
    } else {
      // If the distance is less than 50px, don't draw a line at all
      options.line.setAttribute("x1", 0);
      options.line.setAttribute("y1", 0);
      options.line.setAttribute("x2", 0);
      options.line.setAttribute("y2", 0);
      options.line.setAttribute("marker-start", "");
    }
  }
};

import { GOOGLE_MAPS_API_KEY } from "../core/constants";
import { getScript } from "../core/utils";

export default class SimpleMap {
  constructor(el) {
    if (el) {
      this.el = el;
      getScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`,
        () => {
          this.handleMapsAPILoaded();
        }
      );
    }
  }

  handleMapsAPILoaded() {
    const latlng = new window.google.maps.LatLng(
      this.el.getAttribute("data-map-lat"),
      this.el.getAttribute("data-map-lng")
    );

    const map = new window.google.maps.Map(this.el, {
      zoom: 16,
      center: latlng,
      disableDefaultUI: true,
      scaleControl: true,
      scrollwheel: false,
      gestureHandling: "none"
    });
    const marker = new window.google.maps.Marker({
      position: latlng,
      map: map
    });
    marker.setMap(map);
  }
}

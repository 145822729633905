export default class OpenMenu {
  constructor(el) {
    if (el) {
      this.el = el;

      this.el.addEventListener("click", () => {
        document.body.classList.add("menu-open");
      });
    }
  }
}

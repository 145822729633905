export default class CloseMenu {
  constructor(el) {
    if (el) {
      this.el = el;

      this.el.addEventListener("click", () => {
        document.body.classList.remove("menu-open");
      });
    }
  }
}

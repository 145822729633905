import axios from "axios";
import throttle from "lodash/throttle";

export default class SearchDropdown {
  constructor(el) {
    if (el) {
      const inputEl = el.getElementsByClassName("js-input")[0];
      const searchType = inputEl.getAttribute("data-searchType");
      this.listEl = el.getElementsByClassName("js-list")[0];

      this.getResults = throttle(this._getResults, 500);
      this.previousValue = null;

      inputEl.addEventListener("keyup", e => {
        const query = e.target.value;
        if (query.length >= 3) {
          // Protect against key changes whereby the query hasn't actually changed
          // eg. search, close dropdown, click back in, press a key eg. cursor
          if (query !== this.previousValue) {
            this.getResults(searchType, e.target.value);
            this.listEl.classList.add("js-open");
          }
        } else if (query == "") {
          this.listEl.classList.remove("js-open");
          this.listEl.innerHTML = "";
        }
        this.previousValue = query;
      });

      inputEl.addEventListener("blur", () => {
        // Delay the removal of the dropdown to the link clicks chance to kick in
        setTimeout(() => {
          this.listEl.classList.remove("js-open");
          this.listEl.innerHTML = "";
        }, 200);
      });
    }
  }

  _getResults(searchType, query) {
    axios
      .get(`/api/${searchType}/?query=${query}`)
      .then(({ data }) => {
        let html;
        if (data.length == 0) {
          html =
            "<li class='Dropdown-item'><p class='Dropdown-noResults'>No results</p></li>";
        } else {
          html = data.reduce((acc, curr) => {
            return (
              acc +
              `<li class="Dropdown-item"><a href="${curr.url}" class="Dropdown-link">${curr.name}</a></li>`
            );
          }, "");
        }
        if (this.listEl.classList.contains("js-open")) {
          // Check the dropdown is still open - as the user might have closed it while we were fetching the data
          this.listEl.innerHTML = html;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
}

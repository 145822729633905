import "select2/dist/js/select2.full.js";

import $ from "jquery";

function getOptionTemplate(option) {
  if (!option.id) {
    return option.text;
  } else {
    return $(`<a href="${option.id}">${option.text}</a>`);
  }
}

export default class SearchableSelect {
  constructor(el) {
    if (el) {
      this.el = el;
      const placeholder = this.el.getAttribute("data-placeholder");

      $(this.el).select2({
        theme: "SearchableSelect",
        templateResult: getOptionTemplate,
        placeholder: placeholder
      });

      $(this.el).on("select2:open", e => {
        $("input.select2-search__field").prop("placeholder", "Search...");
      });

      $(this.el).on("select2:selecting", e => {
        e.preventDefault();
        window.location.href = e.target.value;
      });
    }
  }
}

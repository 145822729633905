import {
  createChildAreaMarker,
  createCourseGroupMarker,
  createCourseMarker
} from "../map-helpers/create-markers";

import { GOOGLE_MAPS_API_KEY } from "../core/constants";
import axios from "axios";
import { countryLevelMapStyles } from "../map-styles/country-level-map-styles";
import { getScript } from "../core/utils";
import { stateLevelMapStyles } from "../map-styles/state-level-map-styles";

export default class MarkerMap {
  constructor(el, allowDragging, titleEl) {
    if (el) {
      this.el = el;
      this.titleEl = titleEl;
      this.markers = [];
      this.allowDragging = allowDragging;
      try {
        window.google.maps;
        this.handleMapsAPILoaded();
      } catch {
        getScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`,
          () => {
            this.handleMapsAPILoaded();
          }
        );
      }
    }
  }

  handleMapsAPILoaded() {
    this.map = new window.google.maps.Map(this.el, {
      disableDefaultUI: true,
      scaleControl: true,
      scrollwheel: false,
      gestureHandling: this.allowDragging ? "greedy" : "none",
      zoomControl: false
    });
    // For some annoying reason GM wants to reset the zoom to a whole number
    // after panning, so force it back to where it should be using the stored value
    this.map.addListener("dragend", () => {
      this.map.setOptions({
        zoom: this.zoomLevel
      });
    });
    this.updateAreaId(this.el.getAttribute("data-area-id"));
  }

  updateZoom(zoomLevel) {
    this.map.setZoom(zoomLevel);
  }

  updateAreaId(areaId) {
    this.areaId = areaId;
    // Remove existing markers that were already on the map
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers = [];

    // Use the Area ID to make an API call to get the Area's data,
    // plus child Areas, Courses and CourseGroups so we can update the map
    axios
      .get(`/api/areas/${this.areaId}`)
      .then(({ data: area }) => {
        const latlng = new window.google.maps.LatLng(
          area.center_lat,
          area.center_lng
        );

        const mapStyles =
          area.map_style === "Country level"
            ? countryLevelMapStyles
            : stateLevelMapStyles;

        // For some annoying reason GM wants to reset the zoom to a whole number
        // after panning, so store the value to force it through on dragend
        this.zoomLevel = parseFloat(area.zoom_level);

        this.map.setOptions({
          center: latlng,
          zoom: this.zoomLevel,
          styles: mapStyles
        });

        // Set the new map title
        if (this.titleEl !== undefined)
          this.titleEl.innerHTML = area.verbose_name;

        // Create the child Area markers
        area.child_areas.forEach(childArea => {
          this.markers.push(
            createChildAreaMarker(childArea, () => {
              // Only navigate if we're not in the Admin
              if (!window.location.href.includes("w2g-cms")) {
                this.updateAreaId(childArea.id);
              }
              const event = new CustomEvent("areaClick", {
                detail: {
                  id: childArea.id,
                  name: childArea.name
                }
              });
              this.el.dispatchEvent(event);
            })
          );
        });

        // Create the course group markers
        if (area.course_groups.length > 0) {
          area.course_groups.forEach(courseGroup => {
            courseGroup.courses.forEach(course => {
              // If this course does not have a rating we want to display it on it's own
              // label so we can reflect this properly, so add it to courses
              if (course.rating === "") {
                area.courses.push(course);
              }
            });
            this.markers.push(createCourseGroupMarker(courseGroup));
          });
        }

        // Create the course markers
        if (area.courses.length > 0) {
          area.courses.forEach(course => {
            if (course.lat && course.lng) {
              // Only add to map if lat/lng is not null
              this.markers.push(createCourseMarker(course));
            }
          });
        }

        this.markers.forEach(marker => {
          marker.setMap(this.map);
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
}

export default class RevealContent {
  constructor(el) {
    if (el) {
      this.el = el;

      this.button = this.el.getElementsByClassName("js-row-revealButton")[0];
      this.button.addEventListener("click", () => {
        if (this.el.classList.contains("js-row-open")) {
          this.el.classList.remove("js-row-open");
          this.el.classList.add("js-row-closed");
        } else {
          this.el.classList.add("js-row-open");
          this.el.classList.remove("js-row-closed");
        }
      });
    }
  }
}

export default class Share {
  constructor(el) {
    if (el) {
      this.el = el;

      this.el.addEventListener("click", e => {
        const platform = this.el.getAttribute("data-platform");
        if (platform === "facebook") {
          const uri = window.location.href;
          const res = encodeURI(uri);
          popup(
            "https://www.facebook.com/sharer/sharer.php?u=" + res,
            "",
            550,
            450
          );
          e.preventDefault();
        }
        if (platform === "twitter") {
          const title = document.title + " ";
          const url = "http://twitter.com/intent/tweet?text=" + title;
          popup(url + window.location.href, "", 600, 400);
          e.preventDefault();
        }
      });
    }
  }
}

function popup(url, title, width, height) {
  var x = Number((window.screen.width - width) / 2);
  var y = Number((window.screen.height - height) / 2);

  window.open(
    url,
    "",
    "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=" +
      height +
      ",width=" +
      width +
      ",left=" +
      x +
      ",top=" +
      y
  );
}

import ButtonDropdown from "./components/ButtonDropdown";
import CloseMenu from "./components/CloseMenu";
import CoursePageSections from "./components/CoursePageSections";
import ImageGallery from "./components/ImageGallery";
import ListSort from "./components/ListSort";
import MapOverlay from "./components/MapOverlay";
import OpenMenu from "./components/OpenMenu";
import RevealContent from "./components/RevealContent";
import SearchDropdown from "./components/SearchDropdown";
import SearchableSelect from "./components/SearchableSelect";
import Share from "./components/Share";
import SimpleMap from "./components/SimpleMap";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

document.addEventListener("DOMContentLoaded", () => {
  if (process.env.NODE_ENV !== "development") {
    try {
      Sentry.init({
        dsn: window.bridge.SENTRY_DSN,
        release: window.bridge.VERSION,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
        allowUrls: "where2golf"
      });
    } catch (error) {
      console.log("Error setting up Sentry");
    }
  }

  // Prevent right click and save of images
  document.body.addEventListener("contextmenu", e => {
    if (e.target.tagName === "IMG") {
      e.preventDefault();
    }
  });
  // Prevent drag and save of images
  const images = Array.from(document.getElementsByTagName("IMG"));
  images.forEach(image => {
    image.addEventListener("mousedown", e => {
      e.preventDefault();
    });
  });
  // Make all external links open in new tabs
  const links = Array.from(document.getElementsByTagName("a"));
  links.forEach(link => {
    if (link.hostname !== location.hostname) {
      link.setAttribute("target", "_blank");
    }
  });

  // CoursePageSections
  const sectionNavs = Array.from(
    document.getElementsByClassName("js-sectionNav")
  );
  sectionNavs.forEach(sectionNav => {
    new CoursePageSections(sectionNav);
  });

  // ImageGallery
  const imageGalleries = Array.from(
    document.getElementsByClassName("js-imageGallery")
  );
  imageGalleries.forEach(imageGallery => {
    new ImageGallery(imageGallery);
  });

  // SimpleMap
  const simpleMaps = Array.from(
    document.getElementsByClassName("js-simpleMap")
  );
  simpleMaps.forEach(simpleMap => {
    new SimpleMap(simpleMap);
  });

  // RevealContent
  const courseListRows = Array.from(document.getElementsByClassName("js-row"));
  courseListRows.forEach(courseListRow => {
    new RevealContent(courseListRow);
  });

  // MapOverlay
  const mapOverlayButtons = Array.from(
    document.getElementsByClassName("js-MapOverlay-button")
  );
  mapOverlayButtons.forEach(mapOverlayButton => {
    new MapOverlay(mapOverlayButton);
  });

  // ButtonDropdown
  const buttonDropdowns = Array.from(
    document.getElementsByClassName("js-ButtonDropdown")
  );
  buttonDropdowns.forEach(buttonDropdown => {
    new ButtonDropdown(buttonDropdown);
  });

  // SearchDropdown
  const searchDropdowns = Array.from(
    document.getElementsByClassName("js-SearchDropdown")
  );
  searchDropdowns.forEach(searchDropdown => {
    new SearchDropdown(searchDropdown);
  });

  // SortStart
  const listSorts = Array.from(document.getElementsByClassName("js-listSort"));
  listSorts.forEach(listSort => {
    new ListSort(listSort);
  });

  // OpenMenu
  const openMenuButtons = Array.from(
    document.getElementsByClassName("js-openMenuButton")
  );
  openMenuButtons.forEach(openMenuButton => {
    new OpenMenu(openMenuButton);
  });

  // CloseMenu
  const closeMenuButtons = Array.from(
    document.getElementsByClassName("js-closeMenuButton")
  );
  closeMenuButtons.forEach(closeMenuButton => {
    new CloseMenu(closeMenuButton);
  });

  // Share
  const shareButtons = Array.from(document.getElementsByClassName("js-share"));
  shareButtons.forEach(shareButton => {
    new Share(shareButton);
  });

  // SearchableSelect
  const searchableSelects = Array.from(
    document.getElementsByClassName("js-searchableSelect")
  );
  searchableSelects.forEach(searchableSelect => {
    new SearchableSelect(searchableSelect);
  });
});

// SearchableSelect
const searchableSelects = Array.from(
  document.getElementsByClassName("js-searchableSelect")
);
searchableSelects.forEach(searchableSelect => {
  new SearchableSelect(searchableSelect);
});

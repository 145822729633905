import { AREAS, COURSES, COURSE_GROUPS } from "../core/constants";

import { initCustomMarker } from "./custom-marker";

export const createChildAreaMarker = (childArea, callback) => {
  const CustomMarker = initCustomMarker();
  // The Area marker will be placed on the label lat lng, which may
  // differ from the central lat lng
  const labelLatLng = new window.google.maps.LatLng(
    childArea.center_lat,
    childArea.center_lng
  );
  const areaMarker = new CustomMarker({
    latlng: labelLatLng,
    labelText: childArea.map_name,
    id: childArea.id,
    markerType: AREAS,
    className: "area-marker",
    labelOffsetX: childArea.label_offset_x,
    labelOffsetY: childArea.label_offset_y,
    showLabelLine: "auto",
    isEditMode: window.location.href.includes("w2g-cms"),
    callback: callback
  });
  return areaMarker;
};

export const createCourseGroupMarker = courseGroup => {
  const CustomMarker = initCustomMarker();
  const latlng = new window.google.maps.LatLng(
    courseGroup.courses[0].lat,
    courseGroup.courses[0].lng
  );
  let courses = [];
  let stayAndPlay = false;
  courseGroup.courses.forEach(course => {
    if (course.stay_and_play) stayAndPlay = true;
    if (course.rating !== "") {
      courses.push({
        rating: course.rating,
        name: course.full_name,
        searchName: course.search_name,
        link: course.url
      });
    }
  });

  const courseGroupMarker = new CustomMarker({
    latlng,
    labelText: courseGroup.name,
    courses: courses,
    stayAndPlay: stayAndPlay,
    id: courseGroup.id,
    markerType: COURSE_GROUPS,
    className: "course-marker",
    labelOffsetX: courseGroup.label_offset_x,
    labelOffsetY: courseGroup.label_offset_y,
    showLabelLine: "on",
    isEditMode: window.location.href.includes("w2g-cms")
  });
  return courseGroupMarker;
};

export const createCourseMarker = course => {
  const CustomMarker = initCustomMarker();
  const latlng = new window.google.maps.LatLng(course.lat, course.lng);
  const courseMarker = new CustomMarker({
    latlng,
    labelText: course.map_name,
    courseName: course.full_name,
    searchName: course.search_name,
    rating: course.rating,
    stayAndPlay: course.stay_and_play,
    link: course.url,
    id: course.id,
    markerType: COURSES,
    className: course.rating === "" ? "course-marker future" : "course-marker",
    labelOffsetX: course.label_offset_x,
    labelOffsetY: course.label_offset_y,
    showLabelLine: "on",
    isEditMode: window.location.href.includes("w2g-cms")
  });
  return courseMarker;
};

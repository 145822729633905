export default class CoursePageSections {
  constructor(el) {
    if (el) {
      this.el = el;
      this.navButtons = Array.from(el.getElementsByClassName("js-navButton"));
      this.activeSectionClassName = "js-activeSection";
      this.activeButtonClassName = "js-activeButton";

      this.navButtons.forEach(button => {
        button.addEventListener("click", () => {
          const newSectionName = button.getAttribute("data-section");
          const newSection = document.getElementById(newSectionName);
          if (newSection.classList.contains(this.activeSectionClassName)) {
            // If we are already on that section do nothing
            return;
          } else {
            // Otherwise remove the active class name from the currently active
            // section and button and apply it to the new active section and button
            document
              .getElementsByClassName(this.activeSectionClassName)[0]
              .classList.remove(this.activeSectionClassName);
            document
              .getElementsByClassName(this.activeButtonClassName)[0]
              .classList.remove(this.activeButtonClassName);
            newSection.classList.add(this.activeSectionClassName);
            button.classList.add(this.activeButtonClassName);
          }
        });
      });
    }
  }
}

export default class SortStart {
  constructor(el) {
    if (el) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const orderBy = urlParams.get("order_by");
      const filterBy = urlParams.get("filter_by");
      const items = Array.from(el.getElementsByClassName("js-listSort-item"));
      let currentParam;
      if (orderBy || filterBy) {
        if (items.length > 0)
          currentParam = document.getElementById(`js-listSort-${orderBy}`);
      } else {
        if (items.length > 0) currentParam = items[0];
      }
      currentParam.classList.add("js-listSort-current");
    }
  }
}
